<template>
  <section class="chapter-edit">
    <header class="chapter-edit__header">
      <h3 class="chapter-edit__title">文档编辑</h3>
      <div class="chapter-edit__options">
        <div class="clear-doc" @click="clearDoc">
          <i class="iconfont iconshanchu_icon"></i>
          <el-button type="text">清空文档</el-button>
        </div>
        <el-button class="add" type="primary" @click="addChapter('main')"
          >添加章节</el-button
        >
      </div>
    </header>
    <div class="docList">
      <div
        class="doc-item"
        v-for="doc in selectDocNames"
        :key="doc.document_id"
      >
        <i class="icon-ppt"></i>
        <span>{{ doc.doc_name }}</span>
      </div>
    </div>
    <div
      class="chapter-edit__list"
      id="chapterListWrap"
      v-loading="loading"
      element-loading-text="加载中..."
    >
      <div
        class="chapter-item"
        v-for="(chapter, index) in chapterData"
        :key="chapter.id"
      >
        <div class="main-chapter">
          <div class="chapter-item__info">
            <div class="el-form-item--page">
              {{ chapter.document_id + '-page' }}
              <el-input
                class="chapter-item__info__page"
                v-model.trim="chapter.page"
                @focus="inputFocus(chapter.page)"
                @blur="inputBlur('main', chapter.page, 'page', index)"
              ></el-input>
            </div>
            <el-button type="text" @click="toCurTime(index, 'main')"
              >跳至</el-button
            >
            <div>
              <el-input
                class="chapter-item__info__time"
                @focus="inputFocus(chapter.created_at)"
                @blur="
                  inputBlur('main', chapter.created_at, 'created_at', index)
                "
                v-model.trim="chapter.created_at"
              ></el-input>
            </div>
            <el-input
              class="chapter-item__info__title"
              v-model.trim="chapter.title"
              @focus="inputFocus(chapter.title)"
              @blur="inputBlur('main', chapter.title, 'title', index)"
              placeholder="请输入章节标题"
            ></el-input>
          </div>
          <div class="chapter-item__option">
            <i
              class="iconfont iconshanchu1"
              @click="delchapter('main', index)"
            ></i>
          </div>
        </div>
        <div
          class="sub-chapter"
          v-for="(subChapter, subIndex) in chapter.subsection"
          :key="subChapter.id"
        >
          <div class="chapter-item__info">
            <div>
              {{ subChapter.document_id + '-step' }}
              <el-input
                class="chapter-item__info__page"
                v-model.trim="subChapter.step"
                @focus="inputFocus(subChapter.step)"
                @blur="
                  inputBlur('sub', subChapter.step, 'step', index, subIndex)
                "
              ></el-input>
            </div>
            <el-button type="text" @click="toCurTime(index, 'sub', subIndex)"
              >跳至</el-button
            >
            <div>
              <el-input
                class="chapter-item__info__time"
                @focus="inputFocus(subChapter.created_at)"
                @blur="
                  inputBlur(
                    'sub',
                    subChapter.created_at,
                    'created_at',
                    index,
                    subIndex
                  )
                "
                v-model.trim="subChapter.created_at"
              ></el-input>
            </div>
            <el-input
              class="chapter-item__info__title"
              v-model.trim="subChapter.title"
              @focus="inputFocus(subChapter.title)"
              @blur="
                inputBlur('sub', subChapter.title, 'title', index, subIndex)
              "
              placeholder="请输入章节标题"
            ></el-input>
          </div>
          <div class="chapter-item__option">
            <i
              class="iconfont iconshanchu1"
              @click="delchapter('sub', index, subIndex)"
            ></i>
            <i
              class="iconfont iconzengjia"
              @click="addChapter('sub', index, subIndex)"
              v-if="chapter.subsection.length < chapter.subSize"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="chapter-edit__footer">
      <el-button type="primary" @click="saveChapter" :disabled="isSaved"
        >保存</el-button
      >
      <el-button @click="showConfirmDialog">取消</el-button>
    </div>
    <!-- 保存完成后的列表 -->
    <el-dialog
      title="提示"
      :visible.sync="isShowConfirmDialog"
      width="400px"
      center
      class="confirm-dialog"
    >
      <div class="confirm-dialog__content">
        <i class="iconfont iconjingshi"></i>
        <span class="confirm-dialog__content__tips">{{ tips }}</span>
      </div>
      <div class="dialog-footer">
        <el-button @click="closeConfirm">取 消</el-button>
        <el-button type="primary" @click="cancel">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { APPID, RECORDID } from '../mixin'
export default {
  name: 'chapterEdit',
  data() {
    return {
      isSaved: false,
      chapterData: [],
      tempVal: '',
      loading: true,
      isShowConfirmDialog: false,
      tips: '取消后内容将全部清空，是否取消？',
      type: ''
    }
  },
  created() {
    this.vhallplayer = null
    this.isEdit = this.$route.params.isEdit
    this.accountId = this.$parent._data.accountId
    this.selectDocNames = this.$parent._data.selectDocNames || []
    this.slectedDocIds = this.$route.params.doc_ids
    this.getDocChapterInfo()
    this.defaultChapterInfo = {}
  },
  methods: {
    clearDoc() {
      this.tips = '确认清除文档吗?'
      this.type = 'clear'
      this.isShowConfirmDialog = true
    },
    showConfirmDialog() {
      this.tips = '取消后内容将全部清空，是否取消？'
      this.type = 'cancel'
      this.isShowConfirmDialog = true
    },
    closeConfirm() {
      this.isShowConfirmDialog = false
    },
    delchapter(type, mainIndex, subIndex) {
      if (type === 'main') {
        this.chapterData.splice(mainIndex, 1)
      } else if (type === 'sub') {
        this.chapterData[mainIndex].subsection.splice(subIndex, 1)
        this.chapterData = [...this.chapterData]
      }
    },
    cancel() {
      if (this.type == 'clear') {
        let params = {
          app_id: APPID,
          vod_id: RECORDID
        }
        this.$fetch('delDoc', params)
          .then(() => {
            this.$router.push({ name: 'select-doc' })
          })
          .catch(e => {
            this.$message.error(e.msg)
          })
      } else {
        this.$router.go(-1)
      }
    },
    inputFocus(val) {
      this.tempVal = val
    },
    inputBlur(type, val, expr, mainIndex, subIndex) {
      if (!val) {
        if (type === 'main') {
          this.chapterData[mainIndex][expr] = this.tempVal
        } else if (type === 'sub') {
          this.chapterData[mainIndex].subsection[subIndex][expr] = this.tempVal
        }
      }
    },
    // 检验所有主章节的时间不能为空，且不能重复
    isUniqueTime(timeArr = []) {
      const uniqueTimeArr = Array.from(new Set(timeArr))
      return timeArr.length == uniqueTimeArr.length
    },
    saveChapter() {
      // 验证主章节时间不能重复
      const mainTimeArr = this.chapterData.map(item => item.created_at)
      const isUnique = this.isUniqueTime(mainTimeArr)
      if (!isUnique) {
        this.$message.warning('主章节时间点不能重复')
        return false
      }
      let params = {
        app_id: APPID,
        vod_id: RECORDID,
        doc_titles: JSON.stringify(this.chapterData)
      }
      this.isSaved = true
      const loading = this.$loading()
      let api = this.isEdit ? 'updateDocChapter' : 'createChapterInfo'
      this.$fetch(api, params)
        .then(res => {
          this.isSaved = false
          loading.close()
          this.$router.push({
            name: 'chapter-list',
            params: { doc_ids: this.slectedDocIds }
          })
        })
        .catch(e => {
          this.$message.error(e.msg)
          this.isSaved = false
          loading.close()
        })
    },
    getDocChapterInfo() {
      const params = {
        app_id: APPID
      }
      let api = 'getDocChapterInfo'
      if (!this.isEdit) {
        Object.assign(params, {
          document_id: this.slectedDocIds,
          third_party_user_id: this.accountId
        })
        api = 'getDocChapterInfo'
      } else {
        Object.assign(params, { vod_id: RECORDID })
        api = 'getDocChapterEditInfo'
      }
      this.$fetch(api, params)
        .then(({ data }) => {
          this.chapterData = data.doc_titles.map(chapter => {
            chapter.subSize = chapter.subsection.length
            return chapter
          })
          console.log(this.chapterData)
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          this.$message.error(e.msg)
        })
    },
    addChapter(type, mainIndex, subIndex) {
      let curChapter = {}
      let defalutChapterInfo = {
        title: '',
        remark: '',
        created_at: '00:00:00',
        number: ''
      }
      if (type == 'main') {
        const len = this.chapterData.length
        curChapter = this.chapterData[len - 1]
        Object.assign(defalutChapterInfo, { subsection: [] })
      } else if (type == 'sub') {
        curChapter = this.chapterData[mainIndex].subsection[subIndex]
      }
      Object.assign(defalutChapterInfo, {
        document_id: curChapter.document_id,
        hash: curChapter.hash,
        page: curChapter.page,
        step: curChapter.step
      })
      if (type == 'main') {
        this.chapterData.push(defalutChapterInfo)
        this.$nextTick(() => {
          const chapterListEle = document.querySelector('#chapterListWrap')
          chapterListEle.scrollTop = chapterListEle.scrollHeight
        })
      } else if (type == 'sub') {
        this.chapterData[mainIndex].subsection.splice(
          subIndex + 1,
          0,
          defalutChapterInfo
        )
      }
    },
    toCurTime(index, type, subIndex) {
      const allTime =
        this.$parent._data.vhallplayer &&
        this.$parent._data.vhallplayer.getCurrentTime()
      const fmtTime = this.fmtSecond(allTime)
      if (type === 'main') {
        this.chapterData[index].created_at = fmtTime
      } else if (type === 'sub') {
        this.chapterData[index].subsection[subIndex].created_at = fmtTime
      }
    },
    fmtSecond(time = 5001) {
      // 将秒格式化成00:00:00
      const second = Math.floor(time % 60) + ''
      const hour = Math.floor(time / 60 / 60) + ''
      const minute = Math.floor(time / 60 - hour * 60) + ''
      return (
        hour.padStart(2, 0) +
        ':' +
        minute.padStart(2, 0) +
        ':' +
        second.padStart(2, 0)
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.chapter-edit {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__options {
    display: flex;
    .add {
      margin-left: 10px;
    }
    i {
      font-size: 16px;
      cursor: pointer;
    }
    .clear-doc {
      display: flex;
      align-items: center;
      span {
        padding-left: 5px;
      }
      &:hover i {
        color: @mainColor;
      }
    }
  }
  &__title {
    margin: 0 0 30px 0;
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .docList {
    .doc-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      margin-bottom: 10px;
      .icon-ppt {
        display: block;
        width: 26px;
        height: 31px;
        margin-right: 10px;
        background: url('../img/ppt.png') no-repeat;
        background-size: cover;
      }
    }
  }
  .chapter-edit__list {
    min-height: 200px;
    max-height: calc(100vh - 415px);
    overflow-y: auto;
  }
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
  .chapter-item {
    padding: 8px 0;

    .main-chapter,
    .sub-chapter {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sub-chapter {
      margin: 10px 0 0 20px;
    }
    &__info {
      display: flex;
      flex: 1;
      // margin-bottom: 15px;

      > * {
        margin-left: 10px;
      }
      .el-form-item--page {
        margin-left: 0;
      }
      .el-input {
        width: auto;
      }
      &__page {
        /deep/ .el-input__inner {
          width: 60px;
        }
      }
      &__time {
        /deep/ .el-input__inner {
          width: 90px;
        }
      }
      &__title {
        flex: 1;
        /deep/ .el-input__inner {
          // min-width: 154px;
        }
      }
    }
    &__option {
      > * {
        margin-left: 10px;
      }
    }
    .iconfont {
      font-size: 20px;
      cursor: pointer;
      &.iconshanchu1 {
        color: #ff0b09;
      }
      &.iconzengjia {
        color: @mainColor;
      }
    }
  }
  .chapter-list {
    padding: 10px;
    background: #ededed;
    .chapter-item {
      display: flex;
      align-items: center;
      height: 40px;
      span {
        margin-right: 10px;
      }
    }
  }
  .chapter-edit__footer,
  .chapter__footer {
    margin-top: 40px;
  }
  .confirm-dialog {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .iconfont {
        color: #f01c1f;
        font-size: 40px;
      }
      &__tips {
        margin-top: 15px;
        color: #666;
      }
    }
    .dialog-footer {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      .el-button {
        width: 90px;
        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
